<div *hasPermission="['read:social-accesses']" class="social-buttons">
  <button *ngIf="!twitterSocialAccess?.hasAccess; else twitterUserButton" (click)="twitterSignIn()" class="btn btn-labeled btn-info"><span
      class="btn-label"><em class="fa fa-twitter"></em></span>Link to Twitter Account</button>
  <ng-template #twitterUserButton>
    <div class="btn-group" dropdown>
      <button class="btn btn-labeled btn-info"><span class="btn-label"><em class="fa fa-twitter"></em></span>{{twitterSocialAccess?.username}}</button>
      <button class="btn dropdown-toggle btn-info" type="button" dropdownToggle>
        <span class="caret"></span>
        <span class="sr-only">info</span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li><a (click)="twitterSignOut(twitterSocialAccess)">Sign out</a>
        </li>
      </ul>
    </div>
  </ng-template>
  <button *ngIf="!facebookSocialAccess?.hasAccess; else facebookUserButton" class="btn btn-labeled btn-primary"
    (click)="facebookSignIn()"><span class="btn-label"><em class="fa fa-facebook"></em></span>Link to Facebook Account</button>
  <ng-template #facebookUserButton>
    <div class="btn-group" dropdown>
      <button class="btn btn-labeled btn-primary"><span class="btn-label"><em class="fa fa-facebook"></em></span>{{ facebookCurrentPage?.name || 'loading...' }}</button>
      <button class="btn dropdown-toggle btn-primary" type="button" dropdownToggle>
        <span class="caret"></span>
        <span class="sr-only">primary</span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <ng-container *ngIf="facebookPages.length">
          <li *ngFor="let page of facebookPages">
            <a (click)="facebookSelectPage(page)">{{ page?.name }}</a>
          </li>
          <li class="divider"></li>
        </ng-container>
        <li *hasPermission="['delete:social-accesses']"><a (click)="facebookSignOut(facebookSocialAccess)">Sign out</a>
        </li>
      </ul>
    </div>
  </ng-template>
</div>