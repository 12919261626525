export const environment = {
  production: false,
  baseUrl: 'https://stage-portal.itsmytown.co',
  apiUrl: 'https://data-entry-api-dot-its-my-town-stage02.appspot.com/admin',
  reportServiceUrl: 'https://311service-dot-its-my-town-stage02.appspot.com/admin',
  authConfig: {
    clientID: 'eWYou17w6crdXF2gKcrZqALW3OlFZLGZ',
    domain: 'itsmytown-dev.auth0.com',
    audience: 'https://ITsMyTown',
    callbackURL: 'https://stage-portal.itsmytown.co/callback'
  },
  cloudinaryApiKey: '773541113612443',
  facebookAppId: '278636052867384',
  googleApiKey: 'AIzaSyBu3nqYKppRr8eWORscheIfPflbe0k3QOI',
};
