import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ISocialAccess, SocialMediaService, ISocialPage, ISocial } from '../social-media.service';

@Component({
  selector: 'app-social-checkboxes',
  templateUrl: './social-checkboxes.component.html',
  styleUrls: ['./social-checkboxes.component.scss']
})
export class SocialCheckboxesComponent implements OnInit {
  twitterSocialAccess: ISocialAccess;
  facebookSocialAccess: ISocialAccess;
  facebookCurrentPage: ISocialPage;

  @Input() socialBoxes: ISocial = { facebook: false, twitter: false };

  @Output() socialBoxesChange = new EventEmitter<ISocial>();

  constructor(
    public socialService: SocialMediaService,
  ) { }
  setFacebookPage() {
    if (this.socialBoxes.facebook) {
      this.socialBoxes.facebook = this.facebookCurrentPage.id;
    }
  }
  ngOnInit() {
    // this.hasAccessInit();
    this.socialService.selectedFbPage$.subscribe((selectedFbPage) => {
      this.facebookCurrentPage = (selectedFbPage) ? selectedFbPage : undefined;
    });
    this.socialService.hasAccessFacebook$.subscribe((sas) => {
      this.facebookSocialAccess = sas;
    });
    this.socialService.hasAccessTwitter$.subscribe((sas) => {
      this.twitterSocialAccess = sas;
    });
  }

  // hasAccessInit() {
  //   this.socialService.getSocialAccessStatus()
  //     .subscribe(sas => {
  //       console.log(sas);
  //       this.facebookSocialAccess = sas.find(s => s.type === 'facebook');
  //       this.twitterSocialAccess = sas.find(s => s.type === 'twitter');
  //     });
  // }

}
