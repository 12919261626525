
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const API_URL = `${environment.apiUrl}`;

export interface ITenant {
  name: string;
  viewName: string;
  location?: { latitude: number; longitude: number };
  scopes?: string[];
}
@Injectable()
export class TenantService {
  private tenantsSource = new BehaviorSubject<ITenant[]>([]);
  private currentTenantSource = new BehaviorSubject<ITenant>(null);
  public tenants$ = this.tenantsSource.asObservable();
  public currentTenant$ = this.currentTenantSource.asObservable();

  public userTenants$ = this.auth.userProfile$.pipe(
    map(user => {
      return (user) ? user.tenants : [];
    }),
    switchMap(userTenants =>
      this.tenants$.pipe(
        map(tenants =>
          tenants.filter(tenant => userTenants.some(t => t === tenant.name))
        )
      )
    )
  );

  constructor(
    private auth: AuthService,
    private http: HttpClient
  ) {}
  private get _authHeader(): string {
    if (!this.auth.accessToken) {
      console.log('missing access token');
    }
    return `Bearer ${this.auth.accessToken}`;
  }
  private _readTenantList(): Observable<ITenant[]> {
    return this.http.get<ITenant[]>(`${API_URL}/tenants`, {
      responseType: 'json',
      headers: new HttpHeaders().set('Authorization', this._authHeader),
    }).pipe(
      map(response => {
        return response;
      }),
      catchError(err => {
        console.log(err);
        return observableThrowError(err);
      })
    );
    // return this.authHttp.get(`${API_URL}/tenants`).pipe(
    //   map(response => {
    //     const data: ITenant[] = response.json();
    //     console.log(data);
    //     return data;
    //   }),
    //   catchError(err => {
    //     console.log(err);
    //     return Observable.throw(err);
    //   })
    // );
  }

  setTenant(tenantName: string) {
    console.log('set Tenant: ', tenantName);
    const tenant = this.tenantsSource
      .getValue()
      .find(t => t.name === tenantName);
    this.currentTenantSource.next(tenant);
    localStorage.setItem('current_tenant', tenant.name);
  }

  init() {
    return this._readTenantList()
      .pipe(
        switchMap(tenants => {
          this.tenantsSource.next(tenants);
          return this.userTenants$;
        }),
        map(userTenants => {
          const tenantName = localStorage.getItem('current_tenant');
          let tenant: ITenant;
          if (userTenants && userTenants.length) {
            if (tenantName) {
              tenant = userTenants.find(t => t.name === tenantName);
            }
            if (tenant) {
              this.currentTenantSource.next(tenant);
            } else {
              this.setTenant(userTenants[0].name);
            }
          } else {
            console.log('failed to init the tenant');
          }
        }),
        catchError(err => {
          console.log('error to get tenant from the backend', err);
          return observableThrowError(err);
        })
      );
  }

  // init() {
  //   this._readTenantList().subscribe(
  //     tenants => {
  //       this.tenantsSource.next(tenants);
  //       // getting the current_tenant from local storage
  //       const tenantName = localStorage.getItem('current_tenant');
  //       const tenant = this.tenantsSource
  //         .getValue()
  //         .find(t => t.name === tenantName);
  //       if (tenant) {
  //         this.currentTenantSource.next(tenant);
  //       } else if (tenants) {
  //         this.setTenant(tenants[0].name);
  //       } else {
  //         console.log('failed to init the tenant');
  //       }
  //     },
  //     err => {
  //       console.log('error to get tenant from the backend', err);
  //     }
  //   );
  // }
  get tenantName() {
    const name = this.currentTenantSource.getValue() && this.currentTenantSource.getValue().name;
    return name || localStorage.getItem('current_tenant');
  }
}
