import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ISocialAccess, ISocialPage, SocialMediaService } from '../social-media.service';
import { MatDialog } from '@angular/material/dialog';
import { FacebookPagesDialogComponent } from '../facebook-pages-dialog/facebook-pages-dialog.component';

@Component({
  selector: 'app-social-buttons',
  templateUrl: './social-buttons.component.html',
  styleUrls: ['./social-buttons.component.scss']
})
export class SocialButtonsComponent implements OnInit {

  twitterSocialAccess: ISocialAccess;
  facebookSocialAccess: ISocialAccess;
  facebookCurrentPage: ISocialPage;
  @Output() facebookCurrentPageChange = new EventEmitter<ISocialPage>();

  constructor(
    public socialService: SocialMediaService,
    public dialog: MatDialog,
  ) { }

  initSocialAccessStatus() {
    const sub = this.socialService.getSocialAccessStatus()
      .subscribe(sas => {
        this.facebookSocialAccess = sas.find(s => s.type === 'facebook');
        this.twitterSocialAccess = sas.find(s => s.type === 'twitter');
          this.facebookCurrentPage = (this.facebookCurrentPage)
          ? this.facebookPages.find((p) => this.facebookCurrentPage.id === p.id)
          : undefined;
        if (!this.facebookCurrentPage && this.facebookSocialAccess && this.facebookSocialAccess.pages) {
          const fbPage = this.facebookPages.find(page => !!page.name);
          this.facebookSelectPage(fbPage);
        }
        sub.unsubscribe();
      });
  }
  twitterSignIn() {
    this.socialService.twitterSignIn().subscribe();
  }

  facebookSignIn() {
    this.socialService.facebookSignIn().subscribe((fbAuth) => {
      const dialogRef = this.dialog.open(FacebookPagesDialogComponent, {
        width: '600px',
        data: fbAuth
      });
      dialogRef.afterClosed().subscribe((res: ISocialAccess) => {
        if (!res) { return; }
        this.socialService.facebookUpdateSelectedPages(res)
          .subscribe((fbSa) => {
            this.facebookSocialAccess = fbSa;
            const fbPage = this.facebookPages.find(page => !!page.name);
            if (fbPage) { this.facebookSelectPage(fbPage); }
          });
      });
    });
  }

  get facebookPages() {
    if (!this.facebookSocialAccess || !this.facebookSocialAccess.pages) { return []; }
    const showPages = this.facebookSocialAccess.pages.filter((page) => page.show);
      return showPages;
  }
  facebookSignOut(saFB: ISocialAccess) {
    this.socialService.facebookSignOut(saFB.username).subscribe(() => {
      this.initSocialAccessStatus();
    });
  }
  twitterSignOut(saTw: ISocialAccess) {
    this.socialService.twitterSignOut(saTw.username).subscribe(() => {
      this.initSocialAccessStatus();
    });
  }
  facebookSelectPage(page: ISocialPage) {
    this.socialService.changeSelectedFbPage(page);
    this.facebookCurrentPageChange.emit(this.facebookCurrentPage);
  }

  ngOnInit() {
    this.socialService.selectedFbPage$
    .subscribe((selectedFbPage) => {
      if (selectedFbPage) { this.facebookCurrentPage = selectedFbPage; }
    });
    this.initSocialAccessStatus();
  }


}
