import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from './pages/login/login.component';
import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { ScopeGuardService as ScopeGuard } from '../auth/scope-guard.service';
import { CallbackComponent } from './pages/callback/callback.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      },
      // {
      //   path: 'commboard',
      //   loadChildren: () => import('./staff-commboard/staff-commboard.module').then(m => m.StaffCommboardModule),
      //   canActivateChild: [ScopeGuard],
      //   data: {
      //     expectedScopes: [
      //       'read:staff-commboard',
      //       'create:staff-commboard',
      //       'update:staff-commboard',
      //       'delete:staff-commboard',
      //     ],
      //   },
      // },
      // {
      //   path: 'boards-commissions',
      //   loadChildren: () => import('./boards-commissions/boards-commissions.module').then(m => m.BoardCommissionsModule),
      //   canActivateChild: [ScopeGuard],
      //   data: {
      //     expectedScopes: [
      //       'read:boards-commissions',
      //       'create:boards-commissions',
      //       'update:boards-commissions',
      //       'delete:boards-commissions',
      //     ],
      //   },
      // },
      {
        path: 'readiness',
        loadChildren: () => import('./readiness/readiness.module').then(m => m.ReadinessModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:readiness',
            'create:readiness',
            'update:readiness',
            'delete:readiness',
          ],
        },
      },
      {
        path: 'council-meetings',
        loadChildren:
          () => import('./council-meetings/council-meeting.module').then(m => m.CouncilMeetingModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:council-meetings',
            'create:council-meetings',
            'update:council-meetings',
            'delete:council-meetings',
          ],
        },
      },
      {
        path: 'polls',
        loadChildren: () => import('./poll-survey/poll-survey.module').then(m => m.PollSurveyModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:polls',
            'create:polls',
            'update:polls',
            'delete:polls',
          ],
        },
      },
      {
        path: 'pages',
        loadChildren: () => import('./custom-pages/custom-pages.module').then(m => m.CustomPagesModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:pages',
            'create:pages',
            'update:pages',
            'delete:pages',
          ],
        },
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:issues',
            'create:issues',
            'update:issues',
            'delete:issues',
          ],
        },
      },
      {
        path: 'jobs',
        loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:jobs',
            'create:jobs',
            'update:jobs',
            'delete:jobs',
          ],
        },
      },
      {
        path: 'parks',
        loadChildren: () => import('./parks/parks.module').then(m => m.ParksModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:places',
            'create:places',
            'update:places',
            'delete:places',
          ],
        },
      },
      {
        path: 'city-contacts',
        loadChildren: () => import('./city-contacts/city-contacts.module').then(m => m.CityContactsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:contacts',
            'create:contacts',
            'update:contacts',
            'delete:contacts',
          ],
        },
      },
      {
        path: 'city-news',
        loadChildren: () => import('./city-news/city-news.module').then(m => m.CityNewsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:news',
            'create:news',
            'update:news',
            'delete:news',
          ],
        },
      },
      {
        path: 'city-events',
        loadChildren: () => import('./city-events/city-events.module').then(m => m.CityEventsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:events',
            'create:events',
            'update:events',
            'delete:events',
          ],
        },
      },
      {
        path: 'content-update',
        loadChildren:
          () => import('./content-update/content-update.module').then(m => m.ContentUpdateModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:cvb',
            'create:cvb',
            'update:cvb',
            'delete:cvb',
          ],
        },
      },
      {
        path: 'city-realtime-alerts',
        loadChildren:
          () => import('./city-immediate-alerts/city-immediate-alerts.module').then(m => m.CityImmediateAlertsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:alerts',
            'create:alerts',
            'update:alerts',
            'delete:alerts',
          ],
        },
      },
      {
        path: 'general-news',
        loadChildren: () => import('./general-news/general-news.module').then(m => m.GeneralNewsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:local-news',
            'create:local-news',
            'update:local-news',
            'delete:local-news',
          ],
        },
      },
      {
        path: 'local-businesses',
        loadChildren:
          () => import('./local-businesses/local-businesses.module').then(m => m.LocalBusinessesModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:businesses',
            'create:businesses',
            'update:businesses',
            'delete:businesses',
          ],
        },
      },
      {
        path: 'businesses-out-stand',
        loadChildren:
          () => import('./businesses-out-stand/businesses-out-stand.module').then(m => m.BusinessesOutStandModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:bos	',
            'create:bos',
            'update:bos',
            'delete:bos',
          ],
        },
      },
      {
        path: 'new-to-city',
        loadChildren: () => import('./new-to-city/new-to-city.module').then(m => m.NewToCityModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:stations',
            'create:stations',
            'update:stations',
            'delete:stations',
          ],
        },
      },
      {
        path: 'city-parking',
        loadChildren: () => import('./city-parking/city-parking.module').then(m => m.CityParkingModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:parkings',
            'create:parkings',
            'update:parkings',
            'delete:parkings',
          ],
        },
      },
      {
        path: 'city-tours',
        loadChildren: () => import('./city-tour/city-tour.module').then(m => m.CityTourModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:tours',
            'create:tours',
            'update:tours',
            'delete:tours',
          ],
        },
      },
      {
        path: 'stations',
        loadChildren: () => import('./station/station.module').then(m => m.StationModule),
        canActivateChild: [ScopeGuard],
        // TODO: update the scopes
        data: {
          expectedScopes: [
            'read:stations',
            'create:stations',
            'update:stations',
            'delete:stations',
          ],
        },
      },
      {
        path: 'authors',
        loadChildren: () => import('./authors/authors.module').then(m => m.AuthorsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:authors',
            'create:authors',
            'update:authors',
            'delete:authors',
          ],
        },
      },
      {
        path: 'posts',
        loadChildren: () => import('./posts/posts.module').then(m => m.PostsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:posts',
            'create:posts',
            'update:posts',
            'delete:posts',
          ],
        },
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:profile',
            'create:profile',
            'update:profile',
            'delete:profile',
          ],
        },
      },
      {
        path: 'members',
        loadChildren: () => import('./members/members.module').then(m => m.MembersModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:members',
            'create:members',
            'update:members',
            'delete:members',
          ],
        },
      },
      {
        path: 'menu-builder',
        loadChildren: () => import('./menu-builder/menu-builder.module').then(m => m.MenuBuilderModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:menus',
            'create:menus',
            'update:menus',
            'delete:menus',
          ],
        },
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:users',
            'create:users',
            'update:users',
            'delete:users',
          ],
        },
      },
      {
        path: 'online-services',
        loadChildren:
          () => import('./online-services/online-services.module').then(m => m.OnlineServicesModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:online-services',
            'create:online-services',
            'update:online-services',
            'delete:online-services',
          ],
        },
      },
      {
        path: 'business-categories',
        loadChildren:
          () => import('./business-categories/business-categories.module').then(m => m.BusinessCategoriesModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:business-categories',
            'create:business-categories',
            'update:business-categories',
            'delete:business-categories',
          ],
        },
      },
      {
        path: 'go-big',
        loadChildren: () => import('./go-big/go-big.module').then(m => m.GoBigModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:gobig',
            'create:gobig',
            'update:gobig',
            'delete:gobig',
          ],
        },
      },
      {
        path: 'departments',
        loadChildren: () => import('./departments/departments.module').then(m => m.DepartmentsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:departments',
            'create:departments',
            'update:departments',
            'delete:departments',
          ],
        },
      },
      {
        path: 'tenants',
        loadChildren: () => import('./tenants/tenants.module').then(m => m.TenantsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:tenants',
            'create:tenants',
            'update:tenants',
            'delete:tenants',
          ],
        },
      },
      {
        path: 'activity',
        loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:users',
            'create:users',
            'update:users',
            'delete:users',
          ],
        },
      },
      {
        path: 'file-manager',
        loadChildren: () => import('./media-library/media-library.module').then(m => m.MediaLibraryModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:users',
            'create:users',
            'update:users',
            'delete:users',
          ],
        },
      },
      {
        path: 'highlights',
        loadChildren: () => import('./highlights/highlights.module').then(m => m.HighlightsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:pages',
            'create:pages',
            'update:pages',
            'delete:pages',
          ],
        },
      },
      {
        path: 'jurisdiction',
        loadChildren: () => import('./districts/districts.module').then(m => m.DistrictsModule),
        canActivateChild: [ScopeGuard],
        data: {
          expectedScopes: [
            'read:jurisdiction',
            'create:jurisdiction',
            'update:jurisdiction',
            'delete:jurisdiction',
          ],
        },
      },
    ],
    canActivate: [AuthGuard, ScopeGuard],
  },
  // Not lazy-loaded routes
  { path: 'login', component: LoginComponent },
  { path: 'callback', component: CallbackComponent },
  // Not found
  { path: '**', redirectTo: 'home' },
];
