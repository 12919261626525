import { Injectable } from '@angular/core';
import { Observable ,  from as fromPromise } from 'rxjs';
import { environment } from '../../environments/environment';
import { TenantService } from '../core/tenant/tenant.service';
import { ApiService } from './api.service';
import { ScriptLoaderService } from './script-loader.service';
declare var cloudinary: any;

const CLOUD_NAME = 'itsmytown';
const UPLOAD_PRESET = 'wlfgcrog';

@Injectable()
export class ImageUploadV2Service {
  widget: any;

  constructor(
    private api: ApiService,
    scriptLoader: ScriptLoaderService,
    private tenants: TenantService
  ) {
    scriptLoader
      .load({
        name: 'cloudinay',
        src: '//widget.cloudinary.com/v2.0/global/all.js',
        loaded: false,
      })
      .subscribe(() => {
        // cloudinary.setCloudName(CLOUD_NAME);
        // this.widget = cloudinary.createUploadWidget(
        //   {
        //     cloudName: CLOUD_NAME,
        //     uploadPreset: UPLOAD_PRESET,
        //     uploadSignature: this.generateSignature,
        //   },
        //   (error, result) => {
        //     if (!error && result && result.event === 'success') {
        //       console.log(result);
        //       console.log('Done! Here is the image info: ', result.info);
        //     }
        //   }
        // );
      });
  }

  generateSignature = (callback, params_to_sign) => {
    this.api
      .post<any>(`signature`, { body: params_to_sign })
      .subscribe(
        (response) => {
          const data = response;
          callback(data.signature);
        },
        (err) => {
          console.error(err);
        }
      );
  }

  resultCallback(resolve, reject) {
    return (error, result) => {
      if (!error && result && result.event === 'success') {
        const { info } = result;
        const secureUrl = info.secure_url;
        resolve(secureUrl);
      } else if (error) {
        reject(error);
        console.log(error);
      }
    };
  }

  upload(
    tags: string | string[],
    croppingAspectRatio = 1.5,
    multiple = false
  ): Observable<any> {
    croppingAspectRatio = Number.parseFloat(croppingAspectRatio as any);
    const tagsArr = [];
    tagsArr.push(tags);
    return fromPromise(
      new Promise((resolve, reject) => {
        if (!cloudinary || !cloudinary.openUploadWidget) {
          reject(new Error('cloudinary not set'));
        }
        cloudinary.openUploadWidget(
          {
            cloudName: CLOUD_NAME,
            api_key: environment.cloudinaryApiKey,
            uploadPreset: UPLOAD_PRESET,
            uploadSignature: this.generateSignature,
            sources: ['local', 'url', 'image_search', 'instagram', 'facebook', 'dropbox'],
            clientAllowedFormats: ['png','gif', 'jpeg', 'svg'],
            defaultSource: 'local',
            maxFiles: 1,
            secure: true,
            multiple: false, // Do not work with cropping
            cropping: croppingAspectRatio ? true : false,
            croppingAspectRatio: croppingAspectRatio,
            croppingShowDimensions: croppingAspectRatio ? true : false,
            showSkipCropButton: false,
            googleApiKey: environment.googleApiKey,
            searchByRights: true,
            folder: `${this.tenants.tenantName}`,
            theme: 'minimal',
            styles: {
              palette: {
                tabIcon: '#000000',
                inactiveTabIcon: '#555a5f',
                link: '#00ACC2',
                inProgress: '#00BCD4',
                action: "#00ACC2",
              }
            },
          },
          this.resultCallback(resolve, reject),
        );
      })
    );
  }
}
